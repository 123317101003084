import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { gql } from "../../__generated__";
import { loadStripe } from "@stripe/stripe-js";
import { useCallback } from "react";

const GET_AI_TOKENS = gql(`
  query GetAiTokensPlans {
    getAiTokensPlans {
      productId
      name
      currency {
        isoCode
        symbol
        showBefore
      }
      tokensGiven
      price
    }
  }
`);

export function useGetAiTokens() {
  return useQuery(GET_AI_TOKENS);
}

export function useGetAiTokensPrefetch() {
  const client = useApolloClient();
  return useCallback(() => client.query({ query: GET_AI_TOKENS }), [client]);
}

const STRIPE_SESSION = gql(`
  query GetOneOffCheckoutSession($productId: String!) {
    getOneOffCheckoutSession(productId: $productId) {
      sessionId
    }
  }
`);

export function useStripeSession() {
  const [getSession] = useLazyQuery(STRIPE_SESSION);
  return async (productId: string) => {
    const stripe = await loadStripe(
      import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
    );
    const { data } = await getSession({ variables: { productId } });
    const sessionId = data?.getOneOffCheckoutSession?.sessionId;
    if (sessionId) await stripe?.redirectToCheckout({ sessionId });
  };
}

const HANDLE_CHECKOUT_SESSION_SUCCESS = gql(`
  mutation HandleAiCheckoutSessionSuccess($checkoutSessionId: String!) {
    handleAiCheckoutSessionSuccess(checkoutSessionId: $checkoutSessionId)
  }
`);

export function useHandleCheckoutSessionSuccess() {
  return useMutation(HANDLE_CHECKOUT_SESSION_SUCCESS);
}

const GET_STRIPE_PORTAL = gql(`
  query GetStripePortal($portalSessionInput: PortalSessionInput!) {
    getStripePortalSession(portalSessionInput: $portalSessionInput)
  }
`);

export function useStripePortalQuery() {
  return useLazyQuery(GET_STRIPE_PORTAL, {
    variables: { portalSessionInput: { isTeam: false } },
  });
}

const GET_SUBSCRIPTION_INFO = gql(`
  query GetSubscriptionInfo {
    getProfile {
      aiSubscriberInfo {
        hasActiveSubscription
        store
      }
    }
  }
`);

export function useSubscriptionInfo() {
  return useQuery(GET_SUBSCRIPTION_INFO);
}

export function useSubscriptionPrefetch() {
  const client = useApolloClient();
  return useCallback(
    () => client.query({ query: GET_SUBSCRIPTION_INFO }),
    [client],
  );
}

const GET_CHECKOUT_SESSION = gql(`
  query GetAiCheckoutSession($aiCheckoutSessionInput: AiCheckoutSessionInput!) {
    getAiCheckoutSession(aiCheckoutSessionInput: $aiCheckoutSessionInput) {
      sessionId
    }
  }
`);

export function useCheckoutSessionQuery() {
  return useLazyQuery(GET_CHECKOUT_SESSION);
}
