import {
  ApolloCache,
  QueryHookOptions,
  useApolloClient,
  useMutation,
  useQuery,
} from "@apollo/client";
import { gql } from "../../__generated__";
import { Exact, GetProfileQuery } from "../../__generated__/graphql";
import { amplitude } from "../third-party";

export const GET_PROFILE = gql(`
  query GetProfile {
    getProfile {
      uid
      email
      profilePictureUrl
      name
      aiCredits
      isAiSubscriber
    }
  }
`);

export function removeOneCredit(client: ApolloCache<unknown>) {
  const prevProfile = client.readQuery({ query: GET_PROFILE });
  if (prevProfile?.getProfile?.aiCredits) {
    client.writeQuery({
      query: GET_PROFILE,
      overwrite: true,
      data: {
        ...prevProfile,
        getProfile: {
          ...prevProfile.getProfile,
          aiCredits: prevProfile.getProfile.aiCredits - 1,
        },
      },
    });
  }
}

export function useProfileQuery(
  options?: QueryHookOptions<GetProfileQuery, Exact<Record<string, never>>>,
) {
  return useQuery(GET_PROFILE, options);
}

const REMOVE_PICTURE_MUTATION = gql(`
    mutation RemoveProfilePicture {
      removeProfilePicture {
        message
      }
    }
  `);

export function useRemoveProfilePictureMutation(data?: GetProfileQuery) {
  const [removeProfilePicture] = useMutation(REMOVE_PICTURE_MUTATION);
  return () => {
    amplitude.logEvent(`Web:Client:Profile:RemovePicture:Attempt`);
    return removeProfilePicture({
      optimisticResponse: {
        removeProfilePicture: {
          __typename: "RemoveProfilePictureMutationResponse",
          message: "Profile picture removed",
        },
      },
      update(cache) {
        cache.writeQuery({
          query: GET_PROFILE,
          data: {
            getProfile: {
              ...data!.getProfile!,
              profilePictureUrl: null,
            },
          },
        });
      },
    });
  };
}

const USER_NAME_MUTATION = gql(`
    mutation SetName($name: String!) {
      setName(name: $name) {
        user {
          __typename
          uid
          name
        }
      }
    }
  `);

export function useUserNameMutation(data?: GetProfileQuery) {
  const updateQuery = useUpdateQuery();
  const [setName] = useMutation(USER_NAME_MUTATION);

  return (name: string) => {
    amplitude.logEvent(`Web:Client:Profile:ChangeName:Attempt`);
    updateQuery({ name }, data);
    return setName({ variables: { name } });
  };
}

export function useAddProfilePictureMutation(data?: GetProfileQuery) {
  const updateQuery = useUpdateQuery();
  return (file: File) => {
    amplitude.logEvent(`Web:Client:Profile:AddPicture:Attempt`);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      updateQuery({ profilePictureUrl: reader.result as string }, data);
    };
  };
}

function useUpdateQuery() {
  const client = useApolloClient();
  return (
    profile: Partial<GetProfileQuery["getProfile"]>,
    prevdata?: GetProfileQuery,
  ) => {
    client.writeQuery({
      query: GET_PROFILE,
      data: {
        getProfile: {
          ...prevdata!.getProfile!,
          ...profile,
        },
      },
    });
  };
}
