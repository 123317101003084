import { useApolloClient, useLazyQuery, useQuery } from "@apollo/client";
import { useCallback } from "react";
import { gql } from "../../__generated__";
import { GenerateAiAnimatedPictureInputFromBase64Query } from "../../__generated__/graphql";
import {
  useAiStore,
  useImageInfo,
} from "../../pages/CreatePage/store/selectors";

export const MY_GENERATIONS = gql(`
  query MyGenerations($limit: Int, $cursor: String) {
    myAiGenerations(limit: $limit, cursor: $cursor) {
      data {
        ...GenerationCompleteFragment
      }
      nextCursor
    }
  }
`);

export function useMyGenerationsQuery(variables?: {
  limit?: number;
  cursor?: string;
}) {
  return useQuery(MY_GENERATIONS, {
    variables,
    fetchPolicy: "cache-and-network",
  });
}

export function useMyGenerationsPrefetch() {
  const client = useApolloClient();
  return useCallback(() => client.query({ query: MY_GENERATIONS }), [client]);
}

const UPLOAD_IMAGE_BASE64 = gql(`
  query GenerateAiAnimatedPictureInputFromBase64($base64Data: String!, $fileName: String!) {
  generateAiAnimatedPictureInputFromBase64(base64Data: $base64Data, fileName: $fileName) {
    removedBackgroundPictureUrl
    bucketName
    filePath
    backgroundColorToUse
  }
}
`);

export function useUploadBase64Encoded({
  onCompleted,
}: {
  onCompleted?: (data: GenerateAiAnimatedPictureInputFromBase64Query) => void;
}) {
  const image = useImageInfo();

  const removedBackgroundFileInfo =
    useAiStore.use.removedBackgroundFileInfo?.();
  return useQuery(UPLOAD_IMAGE_BASE64, {
    variables: {
      base64Data: image!.base64!,
      fileName: image!.filePath,
    },
    skip: !!removedBackgroundFileInfo,
    onCompleted,
  });
}

const GET_GENERATIONS_PROGRESS = gql(`
  query GetGenerations($generationsIds: [String!]!) {
    getGenerations(generationsIds: $generationsIds) {
      ...GenerationFragment
    }
  }
`);

export function useGetGenerationsProgress() {
  return useLazyQuery(GET_GENERATIONS_PROGRESS, {
    notifyOnNetworkStatusChange: true,
  });
}

const GET_GENERATIONS_COMPLETE = gql(`
  query GetGenerationsComplete($generationsIds: [String!]!) {
    getGenerations(generationsIds: $generationsIds) {
      ...GenerationCompleteFragment
    }
  }
`);

export function useGetGenerationsComplete() {
  return useLazyQuery(GET_GENERATIONS_COMPLETE, {
    notifyOnNetworkStatusChange: true,
  });
}
