import { useApolloClient, useMutation } from "@apollo/client";
import { gql } from "../../__generated__";
import { GET_GENERATION } from "./generation";
import { MY_GENERATIONS } from "./generationList";
import { AiAnimatedElementsGeneration } from "../../__generated__/graphql";

const NOTIFY_EMAIL_ON_GENERATION_COMPLETE = gql(`
  mutation NotifyEmailOnGenerationComplete(
    $generationId: ID!
  ) {
    notifyEmailOnGenerationComplete(
      generationId: $generationId
    ) {
      generationId
      errorMessages
    }
  }
`);

export function useNotifyEmailOnGenerationComplete(generationId: string) {
  return useMutation(NOTIFY_EMAIL_ON_GENERATION_COMPLETE, {
    variables: { generationId },
  });
}

const SEND_FEEDBACK_FOR_GENERATION = gql(`
  mutation SendFeedbackForGeneration(
    $generationId: ID!
    $rating: Int!
    $message: String
  ) {
    sendFeedbackForGeneration(
      generationId: $generationId
      rating: $rating
      message: $message
    ) {
      generationId
    }
  }
`);

export function useSendFeedbackForGeneration() {
  const [mutate, options] = useMutation(SEND_FEEDBACK_FOR_GENERATION, {});
  const client = useApolloClient();

  const update: typeof mutate = (params) => {
    if (!params?.variables) return mutate(params);
    client.cache.updateQuery(
      {
        query: GET_GENERATION,
        variables: { generationId: params.variables.generationId },
      },
      (data) => {
        if (!data) return data;
        return {
          ...data,
          getAiAnimatedElementsGeneration: {
            ...data.getAiAnimatedElementsGeneration,
            feedback: {
              rating: params.variables!.rating,
              message: params.variables!.message,
            },
          },
        };
      },
    );

    client.cache.updateQuery({ query: MY_GENERATIONS }, (data) =>
      data
        ? {
            ...data,
            myAiGenerations: {
              ...data.myAiGenerations,
              data: data.myAiGenerations.data.map((g) =>
                (g as AiAnimatedElementsGeneration).generationId ===
                params.variables!.generationId
                  ? {
                      ...g,
                      feedback: {
                        rating: params.variables!.rating,
                        message: params.variables!.message,
                      },
                    }
                  : g,
              ),
            },
          }
        : data,
    );
    return mutate(params);
  };

  return [update, options] as const;
}
