import { useApolloClient, useQuery } from "@apollo/client";
import { gql } from "../../__generated__";
import {
  GetAiRevealsPlanPricesQuery,
  PricePeriodicity,
} from "../../__generated__/graphql";
import { useCallback } from "react";

const GET_PRO_PLAN_PRICES = gql(`
  query GetAiRevealsPlanPrices {
    getAiRevealsPlanPrices {
      tokensGiven
      price
      periodicity
      currency {
        symbol
        showBefore
        isoCode
      }
    }
  }
`);

export function useProPlanPricesQuery() {
  return useQuery(GET_PRO_PLAN_PRICES);
}

export function useProPlanPricesQueryPrefetch() {
  const client = useApolloClient();
  return useCallback(
    () => client.query({ query: GET_PRO_PLAN_PRICES }),
    [client],
  );
}

export function getPricesFromPlans(
  prices: GetAiRevealsPlanPricesQuery["getAiRevealsPlanPrices"],
) {
  const yearlyPlan = prices.find(
    (p) => p?.periodicity === PricePeriodicity.Year,
  );

  const monthlyPlan = prices.find(
    (p) => p?.periodicity === PricePeriodicity.Month,
  );

  return {
    currency: yearlyPlan?.currency?.isoCode,
    yearlyPrice: (yearlyPlan?.price ?? 0) / 100,
    monthlyPrice: (monthlyPlan?.price ?? 0) / 100,
  };
}
