import { create, StoreApi, UseBoundStore } from "zustand";
import { createJSONStorage, persist, StateStorage } from "zustand/middleware";
import { get, set, del } from "idb-keyval";
import { AiStore, createAiStore } from "./aiStore";
import { AiRevealAspectRatio } from "../../../__generated__/graphql";
import { useFileUrl } from "../../../api/firebaseStorage";
import {
  base64ToUrl,
  getAspectRatio,
  getAspectRatioString,
} from "../../../common/utils/image";

type WithSelectors<S> = S extends { getState: () => infer T }
  ? S & { use: { [K in keyof T]: () => T[K] } }
  : never;

const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(
  _store: S,
) => {
  const store = _store as WithSelectors<typeof _store>;
  store.use = {};
  for (const k of Object.keys(store.getState())) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
    (store.use as any)[k] = () => store((s) => s[k as keyof typeof s]);
  }

  return store;
};

const storage: StateStorage = {
  async getItem(name: string): Promise<string | null> {
    return (await get(name)) ?? null;
  },
  async setItem(name: string, value: string): Promise<void> {
    await set(name, value);
  },
  async removeItem(name: string): Promise<void> {
    await del(name);
  },
};

export const useAiStore = createSelectors(
  create<AiStore>()(
    persist(createAiStore, {
      name: "ai-store",
      storage: createJSONStorage(() => storage),
    }),
  ),
);

export function useBackgroundColor() {
  return useAiStore(
    (state) =>
      state.backgroundColor ?? state.defaultBackgroundColor ?? undefined,
  );
}

export function useImageInfo() {
  return useAiStore((state) =>
    state.usedFileInfo === "no-background"
      ? state.removedBackgroundFileInfo
      : state.originalFileInfo,
  );
}

export function useImageUrl() {
  const fileInfo = useImageInfo();
  const url = useFileUrl(fileInfo?.bucketName, fileInfo?.filePath);
  return url ?? base64ToUrl(fileInfo?.base64) ?? undefined;
}

export function useAspectRatio(): AiRevealAspectRatio {
  return useAiStore((state) => getAspectRatio(state.format, state.model));
}

export function useAspectRatioString(): string {
  return useAiStore((state) => {
    const ratio = getAspectRatio(state.format, state.model);
    return getAspectRatioString(ratio);
  });
}
