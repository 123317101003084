import { useMemo, useState } from "react";
import {
  AiRevealAspectRatio,
  AiRevealImagePosition,
} from "../../__generated__/graphql";
import { useMeasure } from "@archery-inc/design-system";
import type { Model } from "../../pages/CreatePage/store/aiStore";
import { createUUID } from "./uuid";

export enum AiRevealFormat {
  YouTube = "youtube",
  Shorts = "shorts",
  TikTok = "tiktok",
  Landscape = "landscape",
  InstagramReel = "ig-reel",
  InstagramStory = "ig-story",
  InstagramPost = "ig-post",
  InstagramVideo = "ig-video",
  Square = "square",
  FacebookAd = "fb-ad",
  FacebookPost = "fb-post",
}

export async function compressImage(file: File) {
  const resizedImage = await resizeAndCompressImageToWebP(file);
  const fileExtension = file.name.split(".").pop();
  const fileName = `${await createUUID()}.${fileExtension}`;
  const url = await getBase64FromFile(resizedImage);
  return {
    filePath: fileName,
    base64: url,
  };
}

export function getAspectRatioString(ratio: AiRevealAspectRatio) {
  switch (ratio) {
    case AiRevealAspectRatio.Landscape:
      return "16 / 9";
    case AiRevealAspectRatio.Portrait:
      return "9 / 16";
    case AiRevealAspectRatio.Vertical:
      return "4 / 5";
    case AiRevealAspectRatio.Square:
      return "1 / 1";
    case AiRevealAspectRatio.RunwayLandscape:
      return "5 / 3";
    case AiRevealAspectRatio.RunwayPortrait:
      return "3 / 5";
    default:
      return "9 / 16";
  }
}

export function getAspectRatio(
  format: AiRevealFormat,
  model: Model,
): AiRevealAspectRatio {
  if (model === "runway") {
    switch (format) {
      case AiRevealFormat.Landscape:
        return AiRevealAspectRatio.RunwayLandscape;
      case AiRevealFormat.InstagramReel:
        return AiRevealAspectRatio.RunwayPortrait;
      default:
        return AiRevealAspectRatio.RunwayPortrait;
    }
  }
  switch (format) {
    case AiRevealFormat.YouTube:
    case AiRevealFormat.Landscape:
    case AiRevealFormat.FacebookPost:
      return AiRevealAspectRatio.Landscape;
    case AiRevealFormat.Shorts:
    case AiRevealFormat.TikTok:
    case AiRevealFormat.InstagramReel:
    case AiRevealFormat.InstagramStory:
      return AiRevealAspectRatio.Portrait;
    case AiRevealFormat.InstagramPost:
    case AiRevealFormat.InstagramVideo:
    case AiRevealFormat.FacebookAd:
      return AiRevealAspectRatio.Vertical;
    case AiRevealFormat.Square:
      return AiRevealAspectRatio.Square;
    default:
      return AiRevealAspectRatio.Portrait;
  }
}

export function getFormat(
  ratio: AiRevealAspectRatio,
  model: Model,
): AiRevealFormat {
  if (model === "runway") {
    switch (ratio) {
      case AiRevealAspectRatio.RunwayLandscape:
        return AiRevealFormat.Landscape;
      case AiRevealAspectRatio.RunwayPortrait:
        return AiRevealFormat.InstagramReel;
      default:
        return AiRevealFormat.InstagramReel;
    }
  }
  switch (ratio) {
    case AiRevealAspectRatio.Landscape:
      return AiRevealFormat.YouTube;
    case AiRevealAspectRatio.Portrait:
      return AiRevealFormat.InstagramStory;
    case AiRevealAspectRatio.Vertical:
      return AiRevealFormat.InstagramPost;
    case AiRevealAspectRatio.Square:
      return AiRevealFormat.Square;
    default:
      return AiRevealFormat.InstagramStory;
  }
}

export function useImagePosition(
  imgPosition: AiRevealImagePosition,
  margin = 0.1,
  currentX?: number,
  currentY?: number,
) {
  const [imgAspectRatio, setImgAspectRatio] = useState(0);
  const [ref, { width, height }] = useMeasure();

  const { x, y, imgW, imgH, minMargin } = useMemo(() => {
    if (!width || !height) {
      return { x: 0, y: 0, imgW: 0, imgH: 0, minMargin: 0 };
    }
    let w = Math.floor((1 - 2 * margin) * width);
    let h = Math.ceil(w / imgAspectRatio);
    let minMarg = 0.5 - (height * imgAspectRatio) / (2 * width);
    if (width > height * imgAspectRatio) {
      h = Math.floor((1 - 2 * margin) * height);
      w = Math.ceil(h * imgAspectRatio);
      minMarg = 0.5 - width / (2 * height * imgAspectRatio);
    }

    const p = 0;
    let left: number;
    let top: number;
    switch (imgPosition) {
      case AiRevealImagePosition.Center:
        [left, top] = [(width - w) / 2, (height - h) / 2];
        break;
      case AiRevealImagePosition.Left:
        [left, top] = [p, (height - h) / 2];
        break;
      case AiRevealImagePosition.Right:
        [left, top] = [width - w - p, (height - h) / 2];
        break;
      case AiRevealImagePosition.Top:
        [left, top] = [(width - w) / 2, p];
        break;
      case AiRevealImagePosition.Bottom:
        [left, top] = [(width - w) / 2, height - h - p];
        break;
      case AiRevealImagePosition.TopLeft:
        [left, top] = [p, p];
        break;
      case AiRevealImagePosition.TopRight:
        [left, top] = [width - w - p, p];
        break;
      case AiRevealImagePosition.BottomLeft:
        [left, top] = [p, height - h - p];
        break;
      case AiRevealImagePosition.BottomRight:
        [left, top] = [width - w - p, height - h - p];
        break;
      case AiRevealImagePosition.Custom:
        [left, top] = [currentX! * width, currentY! * height];
        break;
      default:
        [left, top] = [(width - w) / 2, (height - h) / 2];
        break;
    }
    return {
      x: left / width,
      y: top / height,
      imgW: w,
      imgH: h,
      minMargin: minMarg,
    };
  }, [currentX, currentY, height, imgAspectRatio, imgPosition, margin, width]);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    setImgAspectRatio(
      e.currentTarget.naturalWidth / e.currentTarget.naturalHeight,
    );
  };

  return { ref, imgW, imgH, x, y, onImageLoad, width, height, minMargin };
}

function blobToFile(blob: Blob, fileName: string): File {
  return new File([blob], fileName, {
    type: blob.type,
    lastModified: Date.now(),
  });
}

function resizeAndCompressImageToWebP(
  imageFile: File,
  maxSize = 1280,
  quality = 0.8,
) {
  return new Promise<File>((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(imageFile);

    img.onload = () => {
      let width = img.width;
      let height = img.height;
      if (width > maxSize || height > maxSize) {
        if (width > height) {
          height = (height * maxSize) / width;
          width = maxSize;
        } else {
          width = (width * maxSize) / height;
          height = maxSize;
        }
      }

      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(img, 0, 0, width, height);
      } else {
        reject(new Error("Could not create canvas context"));
      }
      canvas.toBlob(
        (blob) => {
          if (blob) {
            const [fileName] = imageFile.name.split(".");
            resolve(blobToFile(blob, `${fileName}.webp`));
          } else {
            reject(new Error("Compression failed"));
          }
        },
        "image/webp",
        quality,
      );
    };

    img.onerror = reject;
  });
}

function getBase64FromFile(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () =>
      resolve((reader.result as string).split(";base64,").pop()!);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

export function base64ToUrl(
  base64: string | undefined,
  contentType = "",
  sliceSize = 512,
) {
  if (!base64) return null;
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return URL.createObjectURL(blob);
}
